import { createSlice } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    selected_client_id: null, // Store the ID of the selected client
    clients: {}, // Object to store clients, where client_id is the key
    selected_client_details: {
      client_id: null,
      first_name: '',
      last_name: '',
      email_address: '',
      date_updated: '',
      filing_status: 'Single',
      income: 0,
    },
  },
  reducers: {
    // Set all clients as a dictionary where client_id is the key
    setClients: (state, action) => {
        const clientsArray = action.payload;
        console.log('Setting Clients in Redux:', clientsArray);  // Log fetched clients to verify structure
      
        // Ensure that client_id is used as the key
        state.clients = clientsArray.reduce((acc, client) => {
          acc[client.client_id] = client;  // Use client_id as the key
          return acc;
        }, {});
        
        console.log('Clients in Redux State after setClients:', state.clients);  // Log state.clients to verify structure
    },
    // Update selected client and set the details of the selected client
    updateSelectedClient: (state, action) => {
        const clientId = action.payload;
        console.log("TRYING TO UPDATE: ", clientId);
        console.log("Current Clients in State:", state.clients);  // Log current state of clients
    
        // Access the client from the state.clients object using clientId as the key
        const client = state.clients[clientId];
        if (client) {
        state.selected_client_id = clientId;
        state.selected_client_details = { ...client };
        console.log("UPDATED SELECTED CLIENT ID: ", state.selected_client_id);
        } else {
        console.log("Client ID not found in state.clients:", clientId);
        }
    },

    // Individual update functions for selected client details
    updateFirstName: (state, action) => {
      state.selected_client_details.first_name = action.payload;
      if (state.selected_client_id !== -1) {
        state.clients[state.selected_client_id].first_name = action.payload;
      }
    },
    updateLastName: (state, action) => {
      state.selected_client_details.last_name = action.payload;
      if (state.selected_client_id !== -1) {
        state.clients[state.selected_client_id].last_name = action.payload;
      }
    },
    updateEmailAddress: (state, action) => {
      state.selected_client_details.email_address = action.payload;
      if (state.selected_client_id !== -1) {
        state.clients[state.selected_client_id].email_address = action.payload;
      }
    },
    updateDateUpdated: (state, action) => {
      state.selected_client_details.date_updated = action.payload;
      if (state.selected_client_id !== -1) {
        state.clients[state.selected_client_id].date_updated = action.payload;
      }
    },
    updateFilingStatus: (state, action) => {
      state.selected_client_details.filing_status = action.payload;
      if (state.selected_client_id !== -1) {
        state.clients[state.selected_client_id].filing_status = action.payload;
      }
    },
    updateIncome: (state, action) => {
      state.selected_client_details.income = action.payload;
      if (state.selected_client_id !== -1) {
        state.clients[state.selected_client_id].income = action.payload;
      }
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setClients,
  updateSelectedClient,
  updateFirstName,
  updateLastName,
  updateEmailAddress,
  updateDateUpdated,
  updateFilingStatus,
  updateIncome
} = clientSlice.actions;

export default clientSlice.reducer;
