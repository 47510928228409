export const updateHome = async (selected_home_id, selectedHomeDetails) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, please log in.');
      return null; // Indicate failure to retrieve token
    }
  
    try {
      // Make the API call to update the home
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_home/${selected_home_id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedHomeDetails),
      });
  
      // Check for HTTP errors
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} ${response.statusText}`);
        return null; // Indicate failure
      }
  
      // Parse and return the status from the response
      const result = await response.json();
      return result.status; // Only return the 'status' field
    } catch (error) {
      console.error(`An error occurred: ${error.message}`);
      return null; // Indicate failure
    }
  };
  