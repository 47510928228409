// src/components/clientComps/PresentationPreview.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styling/PresentationPreview.css';

const PresentationPreview = ({ presentationId }) => {
  const [sharableLink, setSharableLink] = useState('');
  const navigate = useNavigate();

  // Generate a sharable link for the presentation
  const generateSharableLink = () => {
    const link = `${window.location.origin}/preview/${presentationId}`;
    setSharableLink(link);
  };

  // Redirect to a preview page
  const handlePreview = () => {
    navigate(`/preview/${presentationId}`);
  };

  return (
    <div className="presentation-preview-container">
      <button className="preview-button" onClick={handlePreview}>
        Preview Presentation
      </button>

      <button className="generate-link-button" onClick={generateSharableLink}>
        Generate Sharable Link
      </button>

      {sharableLink && (
        <div className="sharable-link">
          <p>Sharable Link:</p>
          <a href={sharableLink} target="_blank" rel="noopener noreferrer">
            {sharableLink}
          </a>
        </div>
      )}
    </div>
  );
};

export default PresentationPreview;
