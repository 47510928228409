import React, { useState, useEffect } from 'react';
import '../../authentication/Modal.css';

const AddHomeModal = ({ show, onClose, onSave, initialData = {} }) => {
  const [address, setAddress] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    if (show) {
      setAddress(initialData.address || '');
      setType(initialData.type || '');
    }
  }, [show, initialData]);

  const handleSave = () => {
    onSave({ id: initialData.id, address, type });
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    show ? (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <h2>{initialData.id ? 'Edit Address' : 'Add Address'}</h2>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">Address</span>
            </div>
            <input
              type="text"
              placeholder="Address"
              className="form-control"
              required
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </div>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">Type</span>
            </div>
            <input
              type="text"
              placeholder="Type"
              className="form-control"
              required
              value={type}
              onChange={e => setType(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button onClick={handleSave}>Save</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default AddHomeModal;
