import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import './styling/Client.css';

const ClientContact = () => {
  const { selectedClient } = useContext(ClientContext);
  const [clientInfo, setClientInfo] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchClientInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_info?client_id=${selectedClient.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setClientInfo(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    const fetchContactInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_contact_info?client_id=${selectedClient.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setContactInfo(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    if (selectedClient) {
      fetchClientInfo();
      fetchContactInfo();
    }
  }, [selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name in clientInfo) {
      setClientInfo({
        ...clientInfo,
        [name]: value
      });
    } else {
      setContactInfo({
        ...contactInfo,
        [name]: value
      });
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const clientResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_client_info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...clientInfo, client_id: selectedClient.id })
      });

      const clientResult = await clientResponse.json();
      if (clientResponse.ok) {
        setMessage('Client information updated successfully.');
      } else {
        setMessage(clientResult.status);
      }

      const contactResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_contact_info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...contactInfo, client_id: selectedClient.id })
      });

      const contactResult = await contactResponse.json();
      if (contactResponse.ok) {
        setMessage('Contact information updated successfully.');
      } else {
        setMessage(contactResult.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="client-page">
      <h2>Client Information</h2>
      {message && <p>{message}</p>}
      <form>
        <label>
          Is this presentation for an individual or general marketing?
          <div>
            <input type="radio" name="presentation_type" value="Individual" checked={clientInfo.presentation_type === 'Individual'} onChange={handleChange} /> Individual
            <input type="radio" name="presentation_type" value="Marketing" checked={clientInfo.presentation_type === 'Marketing'} onChange={handleChange} /> Marketing
          </div>
        </label>
        {/* Other client info fields */}
        <label>
          First name
          <input type="text" name="first_name" value={clientInfo.first_name || ''} onChange={handleChange} />
        </label>
        {/* Add the rest of client-related fields here... */}
        
        {/* Contact Info Fields */}
        <h2>Contact Information</h2>
        <label>
          Email address
          <input type="email" name="email" value={contactInfo.email || ''} onChange={handleChange} />
        </label>
        <label>
          Phone / Fax
          <input type="text" name="phone_fax" value={contactInfo.phone_fax || ''} onChange={handleChange} />
        </label>
        {/* Add the rest of contact-related fields here... */}

      </form>
      <button onClick={handleSubmit}>Save Information</button>
    </div>
  );
};

export default ClientContact;
