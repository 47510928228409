import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Redux hooks
import { updateSelectedClient, setClients, updateFilingStatus, updateIncome } from '../../state/client/clientSlice';
import { setHomes, updateSelectedHome, updateSelectedClientForHome } from '../../state/home/homeSlice';
import LoanInformation from '../components/LoanInformation';
import LoanPayments from '../components/LoanPayments';
import LoanDetails from '../components/LoanDetails';
import ComparisonSection from '../components/ComparisonSection'; // Add ComparisonSection import
import { Dropdown } from 'primereact/dropdown'; // Import PrimeReact Dropdown
import './MortgageCalculator.css';
import { fetchClients } from '../../utils/fetchClients'; // Import fetchClients function
import { fetchHomes } from '../../utils/fetchHomes';
import { calculateComparisonForChart } from '../../utils/mathUtils'; // Import the function

function MortgageCalculator({
  setLoanData,
  setPaymentList,
  setSelectedPaymentRange,
  setFilingStatus,
  setIncome,
  setYears,
  rentAmount,
  setRentAmount,
  setPropertyTaxes,
  setTotalInterest,
  setTaxRate,
}) {
  const dispatch = useDispatch();

  // Access Redux state using useSelector
  const selectedClientId = useSelector(state => state.client.selected_client_id); // Correctly get from Redux
  const selectedHomeDetails = useSelector(state => state.home.selected_home_details);
  const selectedHome = useSelector(state => state.home.selected_home_id);
  const clients = useSelector(state => state.client.clients);
  const homes = useSelector(state => state.home.homes);

  // Local states
  const [years, setInternalYears] = useState(30);
  const [selectedPaymentRange, setInternalSelectedPaymentRange] = useState(0);
  const [showTableCells, setShowTableCells] = useState(false);
  const [message, setMessage] = useState('');
  const [comparisonResults, setComparisonResults] = useState([]); // State for ComparisonSection
  const [expandedYear, setExpandedYear] = useState(null); // State for expanding year in ComparisonSection
  const yearOptions = [15, 20, 25, 30];

  // Fetch clients and homes on component mount and save to Redux
  useEffect(() => {
    fetchClients((fetchedClients) => {
      dispatch(setClients(fetchedClients)); // Store the clients in Redux
    }, setMessage);
  }, [dispatch]);

  useEffect(() => {
    if (selectedClientId) {
      fetchHomes(selectedClientId, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes)); // Store the homes in Redux
      }, setMessage);
    }
  }, [selectedClientId, dispatch]);

  // Update the chart when the home or related data changes
  useEffect(() => {
    if (selectedHomeDetails && selectedHome) {
      const results = calculateComparisonForChart(selectedHomeDetails, years, rentAmount);
      setComparisonResults(results);
    }
  }, [selectedHomeDetails, selectedHome, years, rentAmount]);

  // Handle initial home selection case - rendering chart for initially selected home
  useEffect(() => {
    if (selectedHomeDetails) {
      const initialResults = calculateComparisonForChart(selectedHomeDetails, years, rentAmount);
      setComparisonResults(initialResults);
    }
  }, [selectedHomeDetails, selectedHome, comparisonResults.length, years, rentAmount]);

  // Handle home selection change and immediately update chart
  const handleHomeSelection = (homeId) => {
    const selectedHome = Object.values(homes).find(home => home.home_id === homeId);
    if (selectedHome) {
      dispatch(updateSelectedHome(selectedHome.home_id)); // Dispatch selected home to Redux
    }
  };

  const handleRentChange = (event) => {
    const newRentAmount = parseFloat(event.target.value);
    setRentAmount(newRentAmount);
  };

  return (
    <div className="mortgage-calculator">
      <div className="top-row">
        <div className="dropdowns">
          {/* Client Dropdown */}
          <div className="form-group">
            <Dropdown
              value={selectedClientId}
              options={Object.values(clients).map(client => ({
                label: `${client.first_name} ${client.last_name}`,
                value: client.client_id
              }))}
              onChange={(e) => {
                const selectedClient = Object.values(clients).find(client => client.client_id === e.value);
                if (selectedClient) {
                  dispatch(updateSelectedClient(selectedClient.client_id)); // Dispatch the correct client_id to Redux
                  dispatch(updateSelectedClientForHome(selectedClient.client_id));
                  dispatch(updateSelectedHome(null)); // Reset selected home
                }
              }}
              placeholder="Select a Client"
              filter // Enable search functionality
            />
          </div>

          {selectedClientId && (
            <div className="form-group">
              {/* Home Dropdown */}
              <Dropdown
                value={selectedHome}
                options={Object.values(homes).map(home => ({
                  label: home.address,
                  value: home.home_id
                }))}
                onChange={(e) => {
                  handleHomeSelection(e.value); // Pass home ID to selection handler
                }}
                placeholder="Select a Home"
                filter // Enable search functionality
              />
            </div>
          )}
        </div>
      </div>

      <div className="middle-row">
        {selectedClientId && <LoanDetails loanData={{ ...selectedHomeDetails }} />}
        {selectedHome && (
          <LoanInformation
            loanDetails={selectedHomeDetails}
            yearOptions={yearOptions}
          />
        )}
      </div>

      {selectedHome && (
        <>
          <div className="finance-info">
            <LoanPayments
              showTableCells={showTableCells}
              setShowTableCells={setShowTableCells}
              selectedPaymentRange={selectedPaymentRange}
              setSelectedPaymentRange={setInternalSelectedPaymentRange}
            />
          </div>

          {/* Add ComparisonSection, only shown when a home is selected */}
          <ComparisonSection
            comparisonResults={comparisonResults}
            setComparisonResults={setComparisonResults}
            expandedYear={expandedYear}
            setExpandedYear={setExpandedYear}
          />
        </>
      )}
    </div>
  );
}

export default MortgageCalculator;
