// src/pages/Presentation.js

import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import ComparisonSectionClient from '../../components/clientComps/ComparisonSectionClient';
import { fetchHomes } from '../../components/clientComps/fetchLoanDataClient';
import MonthlyPaymentComparison from '../../components/clientComps/MonthlyPaymentComparison';
import ComparisonTable from '../../components/clientComps/ComparisonTable';
import PresentationPreview from './PresentationPreview'; // Import PresentationPreview
import './styling/Presentation.css';

import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedClient } from '../../../state/client/clientSlice';
import { setHomes } from '../../../state/home/homeSlice'; // Import setHomes action

const Presentation = () => {
    const dispatch = useDispatch();
    const selectedClient = useSelector(state => state.client.selected_client_id);
    const selectedClientDetails = useSelector(state => state.client.selected_client_details);

    const homes = useSelector(state => state.home.homes); // Object of homes
    const homesArr = Object.values(homes); // Convert homes object to array
    const selectedHomeId =  useSelector(state => state.home.selected_home_id);
    const selectedHome = homesArr.find(home => home.id === selectedHomeId);

  
    const calculateTaxDeductions = (home) => {
      if (!home) {
        console.error('Home data is missing or undefined');
        return 0;
      }
      
      const interestDeduction = home.home_price * (home.interest_rate / 100);
      const propertyTaxDeduction = home.home_price * (home.property_tax_rate / 100);
      return interestDeduction + propertyTaxDeduction;
    };
  
    const presentationId = selectedClient;
  
    return (
      <div className="presentation-container">
        <header className="presentation-header">
          <h1>Hi {selectedClientDetails?.first_name}!</h1>
          <p>
            Welcome to your personal Total Cost Analysis for a home loan. Here you can compare our different loan products from a short and long-term perspective. Thank you for letting us be your partner in your mortgage experience. Please let us know if you need help in any way.
          </p>
        </header>
  
        {/* Rent vs Own Comparison Chart */}
        <div className="comparison-section">
          <h2>Rent vs Own Comparison</h2>
          <ul>
            {homesArr.map((home) => (
              <li key={home.id}>
                <h3>{home.address}</h3>
                <ComparisonSectionClient home={home} />
              </li>
            ))}
          </ul>
        </div>
  
        {/* Possible Tax Deductions Section */}
        <div className="tax-deductions-section">
          <h2>Possible Tax Deductions</h2>
          <ul>
            {homesArr.map((home) => (
              <li key={home.id}>
                <h3>{home.address}</h3>
                <p>Possible Tax Deduction: ${calculateTaxDeductions(home).toFixed(2)}</p>
              </li>
            ))}
          </ul>
        </div>
  
        {/* Monthly Payment Comparison Chart */}
        <div className="monthly-payment-comparison">
          <MonthlyPaymentComparison homes={homesArr} />
        </div>
  
        {/* Comparison Table */}
        <div className="comparison-table">
          <ComparisonTable homes={homesArr} />
        </div>
  
        {/* Presentation Preview and Sharable Link */}
        <PresentationPreview presentationId={presentationId} />
  
        <footer className="presentation-footer">
          <p>
            ** Your actual rate, payment, and costs could be higher. Get an official Loan Estimate before choosing a loan.
          </p>
        </footer>
      </div>
    );
  };
  

export default Presentation;
