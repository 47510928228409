import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user_id: null,
    username: '',
    password: '',
    email_address: '',
    is_verified: false,
    verification_token: null,
    is_approved: false,
    reset_token_expiration: null,
    reset_token: null,
  },
  reducers: {
    updateUserId: (state, action) => {
      state.user_id = action.payload
    },
    updateUsername: (state, action) => {
      state.username = action.payload
    },
    updateClientId: (state, action) => {
        state.first_name = action.payload
    },
    updateLastName: (state, action) => {
        state.last_name = action.payload
    },
    updateEmailAddress: (state, action) => {
        state.email_address = action.payload
    },
    updateDateUpdated: (state, action) => {
        state.date_updated = action.payload
    },
    updateFilingStatus: (state, action) => {
        state.filing_status = action.payload
    },
    updateIncome: (state, action) => {
        state.income = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { 
    updateUserId, updateClientId, updateLastName, updateEmailAddress, updateDateUpdated, 
    updateFilingStatus, updateIncome, updateUsername
 } = userSlice.actions
export default userSlice.reducer