import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Redux hooks
import { fetchClients } from '../../../utils/fetchClients';
import { fetchHomes } from '../../../utils/fetchHomes';
import { fetchClientData } from '../../../utils/fetchClientData';
import { handleAddHome } from './inputUtils';
import {
  updateSelectedClient, setClients, updateFilingStatus, updateIncome
} from '../../../state/client/clientSlice';
import {
  setHomes, addHome, updateSelectedHome, updateSelectedClientForHome,
  updateHomePrice, updateDownPayment, updateInterestRate, updatePropertyTaxRate,
  updateHomeInsuranceFee, updatePMIRate, updateHOAFee, updateRentAmountMonthly,
  updateDatabaseThunk
} from '../../../state/home/homeSlice'; // Import necessary home actions
import NumericInput from '../NumericInput';
import DropdownWithSearch from './DropdownWithSearch';
import DropdownWithSearchClients from './DropdownWithSearchClients';
import './Inputs.css';
import { Dropdown } from 'primereact/dropdown'; // Import PrimeReact Dropdown
import AddHomeModal from './AddHomeModal'; // Import the HomeModal component


const Inputs = () => {
  const dispatch = useDispatch();

  // Accessing values from Redux instead of local state
  const selectedClient = useSelector(state => state.client.selected_client_id); // Get selected client ID from Redux
  const selectedHomeDetails = useSelector(state => state.home.selected_home_details); // Get selected home details from Redux

  const selectedHome = useSelector(state => state.home.selected_home_id); // Get selected home ID from Redux
  const clients = useSelector(state => state.client.clients); // Get clients from Redux (object)
  const homes = useSelector(state => state.home.homes); // Get homes from Redux

  // Individual home detail values from Redux
  const homePrice = useSelector(state => state.home.selected_home_details.home_price);
  const downPayment = useSelector(state => state.home.selected_home_details.down_payment);
  const interestRate = useSelector(state => state.home.selected_home_details.interest_rate);
  const propertyTaxRate = useSelector(state => state.home.selected_home_details.property_tax_rate);
  const homeInsuranceFee = useSelector(state => state.home.selected_home_details.home_insurance_fee);
  const pmiRate = useSelector(state => state.home.selected_home_details.pmi_rate);
  const hoaFee = useSelector(state => state.home.selected_home_details.hoa_fee);
  const rentAmount = useSelector(state => state.home.selected_home_details.rent_amount_monthly);

  const income = useSelector(state => state.client.selected_client_details.income);
  const filingStatus = useSelector(state => state.client.selected_client_details.filing_status); // Correct key name

  const [message, setMessage] = useState(''); // Holds the banner message
  const [showBanner, setShowBanner] = useState(false); // Controls banner visibility

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // Fetch clients and store them in Redux
  useEffect(() => {
    fetchClients((fetchedClients) => {
      dispatch(setClients(fetchedClients)); // Store clients in Redux
    }, setMessage);
  }, [dispatch]);

  // Fetch homes when a client is selected
  useEffect(() => {
    if (selectedClient) {
      fetchHomes(selectedClient, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes)); // Store homes in Redux
      }, setMessage);
    }
  }, [selectedClient, dispatch]);

  const handleHomeSelection = (homeId) => {
    const selectedHome = Object.values(homes).find(home => home.home_id === homeId);
    if (selectedHome) {
      dispatch(updateSelectedHome(selectedHome.home_id)); // Dispatch selected home to Redux
    }
  };

  const handleSaveHome = (newHome) => {
    handleAddHome(
      selectedClient,
      newHome.address,
      newHome.type,
      (addedHome) => {
        dispatch(addHome(addedHome)); // Add the new home using the response from the backend
  
        // Update the selected home to the newly added home
        if (addedHome.home_id) {
          dispatch(updateSelectedHome(addedHome.home_id));
        }
        
        setShowAddModal(false); // Close modal after saving
        setMessage('Home added successfully.');
      },
      homes,
      setShowAddModal,
      setMessage
    );
  };
  

  // Convert clients object to an array for mapping
  const clientItems = Object.values(clients).map(client => ({
    id: client.client_id,
    label: `${client.first_name} ${client.last_name} (${client.email_address})`,
  }));

  const homeItems = Object.values(homes).map(home => ({
    id: home.home_id,
    label: home.address,
  }));

  return (
    <div className="inputs-container">
      <h1 className="inputs-title">Inputs</h1>
      <div className="inputs-dropdown-group">
        <div className="inputs-dropdown">
          {/* Client Dropdown */}
          <div className="form-group">
            <Dropdown
              value={selectedClient}
              options={Object.values(clients).map(client => ({
                label: `${client.first_name} ${client.last_name}`,
                value: client.client_id
              }))}
              onChange={(e) => {
                const selectedClient = Object.values(clients).find(client => client.client_id === e.value);
                if (selectedClient) {
                  dispatch(updateSelectedClient(selectedClient.client_id)); // Dispatch the correct client_id to Redux
                  dispatch(updateSelectedClientForHome(selectedClient.client_id));
                  dispatch(updateSelectedHome(null)); // Reset selected home
                }
              }}
              placeholder="Select a Client"
              filter // Enable search functionality
            />
          </div>
          {selectedClient && (
            <div className="inputs-row">
              <div className="inputs-label-input-group">
                <label className="inputs-form-label">Filing Status:</label>
                <select
                  value={filingStatus}
                  onChange={(e) => dispatch(updateFilingStatus(e.target.value))}
                  className="inputs-select"
                >
                  <option value="Single">Single</option>
                  <option value="Jointly">Jointly</option>
                  <option value="HeadOfHousehold">Head Of Household</option>
                </select>
              </div>
              <div className="inputs-label-input-group">
                <label className="inputs-form-label">Income: $</label>
                <NumericInput 
                  value={income} 
                  setValue={(value) => dispatch(updateIncome(value))} 
                />
              </div>
            </div>
          )}
        </div>
        {selectedClient && (
          <div className="inputs-dropdown-home-group">
            {/* Home Dropdown */}
            <div className="form-group">
              <Dropdown
                value={selectedHome}
                options={Object.values(homes).map(home => ({
                  label: home.address, // Make sure this matches the correct property name
                  value: home.home_id
                }))}
                onChange={(e) => {
                  handleHomeSelection(e.value); // Pass home ID to selection handler
                }}
                placeholder="Select a Home"
                filter // Enable search functionality
              />
            </div>
            <button
              className="add-home-button"
              onClick={() => setShowAddModal(true)}
            >
              Add Home
            </button>
          </div>
        )}
      </div>

      <AddHomeModal
              show={showAddModal || showEditModal}
              onClose={() => {
                setShowAddModal(false);
                setShowEditModal(false);
              }}
              onSave={handleSaveHome} // Corrected to use handleSaveHome
              initialData={selectedClient || { address: '', type: '' }}
            />

      {showBanner && (
        <div className={`banner ${message.includes('Error') ? 'error-banner' : 'success-banner'}`}>
          {message}
        </div>
      )}
      {/* Form inputs for the selected home */}
      <div className="inputs-form-group">
        {selectedHome && (
          <div className="inputs-table">
            {[
              { label: "Home Price", value: homePrice ?? 0, action: updateHomePrice, symbol: '$' },
              { label: "Down Payment", value: downPayment ?? 0, action: updateDownPayment, symbol: '$' },
              { label: "Interest Rate", value: interestRate ?? 0, action: updateInterestRate, symbol: '%' },
              { label: "Property Tax Rate", value: propertyTaxRate ?? 0, action: updatePropertyTaxRate, symbol: '%' },
              { label: "Home Insurance Fee", value: homeInsuranceFee ?? 0, action: updateHomeInsuranceFee, symbol: '$' },
              { label: "PMI Rate", value: pmiRate ?? 0, action: updatePMIRate, symbol: '%' },
              { label: "HOA Fees", value: hoaFee ?? 0, action: updateHOAFee, symbol: '$' },
              { label: "Rent Amount", value: rentAmount ?? 0, action: updateRentAmountMonthly, symbol: '$' }
            ].map((input, index) => (
              <div key={index} className="inputs-cell">
                <NumericInput 
                  label={input.label} 
                  value={input.value} 
                  setValue={(value) => dispatch(input.action(value))} 
                  symbol={input.symbol}
                />
              </div>
            ))}
          </div>

        )}
        {selectedHome && (
          <button
            className="inputs-save-button"
            onClick={() => {
              console.log('Saving Inputs:', {
                homePrice, downPayment, interestRate, propertyTaxRate, homeInsuranceFee, pmiRate, hoaFee, rentAmount
              });

              // Update Redux state first
              dispatch(updateHomePrice(homePrice));
              dispatch(updateDownPayment(downPayment));
              dispatch(updateInterestRate(interestRate));
              dispatch(updatePropertyTaxRate(propertyTaxRate));
              dispatch(updateHomeInsuranceFee(homeInsuranceFee));
              dispatch(updatePMIRate(pmiRate));
              dispatch(updateHOAFee(hoaFee));
              dispatch(updateRentAmountMonthly(rentAmount));

              const updateHomeDetails = {
                home_price: parseFloat(homePrice), // Remove commas and convert to a float
                down_payment: parseFloat(downPayment), // Convert string to float if necessary
                interest_rate: parseFloat(interestRate),
                property_tax_rate: parseFloat(propertyTaxRate),
                home_insurance_fee: parseFloat(homeInsuranceFee),
                pmi_rate: parseFloat(pmiRate),
                hoa_fee: parseFloat(hoaFee),
                rent_amount: parseFloat(rentAmount),
              };

              // Dispatch the thunk to update the database
              dispatch(updateDatabaseThunk({ 
                selected_home_id: selectedHome, // Replace this with the actual selected home ID
                selectedHomeDetails: updateHomeDetails 
              }))
              .then(() => {
                setMessage('Inputs saved and database updated successfully!');
                setShowBanner(true);
        
                // Hide the banner after 3 seconds
                setTimeout(() => setShowBanner(false), 3000);
              })
              .catch((error) => {
                console.error('Error updating the database:', error);
                setMessage('Error saving inputs. Please try again.');
                setShowBanner(true);
        
                // Hide the banner after 3 seconds
                setTimeout(() => setShowBanner(false), 3000);
              });
            }}
          >
            Save Inputs
          </button>
        )}
      </div>
    </div>
  );
};

export default Inputs;
