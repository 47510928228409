import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import './styling/AffordabilityExpenditure.css';

const AffordabilityExpenditure = () => {
  const { selectedClient } = useContext(ClientContext);
  const [affordability, setAffordability] = useState({});
  const [expenditure, setExpenditure] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchAffordability = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_affordability?client_id=${selectedClient.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setAffordability(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    const fetchExpenditure = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_expenditure?client_id=${selectedClient.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setExpenditure(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    if (selectedClient) {
      fetchAffordability();
      fetchExpenditure();
    }
  }, [selectedClient]);

  const handleChange = (e, type) => {
    const { name, value } = e.target;
    if (type === 'affordability') {
      setAffordability({
        ...affordability,
        [name]: value
      });
    } else if (type === 'expenditure') {
      setExpenditure({
        ...expenditure,
        [name]: value
      });
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const affordabilityResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_affordability`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...affordability, client_id: selectedClient.id })
      });

      const affordabilityResult = await affordabilityResponse.json();
      if (affordabilityResponse.ok) {
        setMessage('Affordability information updated successfully.');
      } else {
        setMessage(affordabilityResult.status);
      }

      const expenditureResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_expenditure`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...expenditure, client_id: selectedClient.id })
      });

      const expenditureResult = await expenditureResponse.json();
      if (expenditureResponse.ok) {
        setMessage('Expenditure information updated successfully.');
      } else {
        setMessage(expenditureResult.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="accountability-expenditure-page">
      <h2>Affordability & Expenditure Information</h2>
      {message && <p>{message}</p>}
      <form>
        {/* Affordability Section */}
        <div className="affordability-section">
          <h3>Affordability Information</h3>
          <label>
            Current annual gross income
            <input
              type="text"
              name="current_annual_gross_income"
              value={affordability.current_annual_gross_income || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
          <label>
            Credit status
            <select
              name="credit_status"
              value={affordability.credit_status || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            >
              <option value="Excellent">Excellent</option>
              <option value="Good">Good</option>
              <option value="Fair">Fair</option>
              <option value="Poor">Poor</option>
            </select>
          </label>
          <label>
            Savings balance
            <input
              type="text"
              name="savings_balance"
              value={affordability.savings_balance || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
          <label>
            Savings interest rate %
            <input
              type="text"
              name="savings_interest_rate"
              value={affordability.savings_interest_rate || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
          <label>
            Standard deduction
            <input
              type="text"
              name="standard_deduction"
              value={affordability.standard_deduction || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
          <label>
            Tax bracket %
            <input
              type="text"
              name="tax_bracket"
              value={affordability.tax_bracket || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
          <label>
            Filing status
            <select
              name="filing_status"
              value={affordability.filing_status || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            >
              <option value="Married Filing Jointly">Married Filing Jointly</option>
              <option value="Single">Single</option>
              <option value="Head of Household">Head of Household</option>
            </select>
          </label>
          <label>
            Annual MCC tax credit %
            <input
              type="text"
              name="annual_mcc_tax_credit"
              value={affordability.annual_mcc_tax_credit || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
          <label>
            Maximum MCC tax credit
            <input
              type="text"
              name="maximum_mcc_tax_credit"
              value={affordability.maximum_mcc_tax_credit || ''}
              onChange={(e) => handleChange(e, 'affordability')}
            />
          </label>
        </div>

        {/* Expenditure Section */}
        <div className="expenditure-section">
          <h3>Expenditure Information</h3>
          <label>
            Maximum mortgage payment
            <input
              type="text"
              name="maximum_mortgage_payment"
              value={expenditure.maximum_mortgage_payment || ''}
              onChange={(e) => handleChange(e, 'expenditure')}
            />
          </label>
          <label>
            Ideal mortgage payment
            <input
              type="text"
              name="ideal_mortgage_payment"
              value={expenditure.ideal_mortgage_payment || ''}
              onChange={(e) => handleChange(e, 'expenditure')}
            />
          </label>
          <div className="price-range">
            <label>Price range for a new home</label>
            <div className="price-inputs">
              <input
                type="text"
                name="price_range_low"
                value={expenditure.price_range_low || ''}
                placeholder="Low"
                onChange={(e) => handleChange(e, 'expenditure')}
              />
              <input
                type="text"
                name="price_range_high"
                value={expenditure.price_range_high || ''}
                placeholder="High"
                onChange={(e) => handleChange(e, 'expenditure')}
              />
            </div>
          </div>
        </div>
      </form>

      <button onClick={handleSubmit}>Save Information</button>
      <a href="https://www.irs.gov/credits-deductions/tax-brackets" target="_blank" rel="noopener noreferrer">
        Find tax bracket
      </a>
    </div>
  );
};

export default AffordabilityExpenditure;
