// src/utils/fetchHomes.js
export const fetchHomes = async (client_id, setHomes) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('No token found, please log in.');
    return;
  }

  try {
    // Make the API call to fetch homes
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_homes?client_id=${client_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (response.ok) {
      console.log('Fetched Homes:', result); // Log fetched clients
      setHomes(result);
    } else {
      console.log(result.status);
    }
  } catch (error) {
    console.log(`An error occurred: ${error.message}`);
  }
};