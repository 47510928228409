import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import Redux hooks
import MortgageCalculator from '../../components/MortgageCalculator';
import ComparisonSection from '../../components/ComparisonSection';
import { generatePDF } from '../../../utils/generatePDF';
import { calculateComparisonForChart, calculateLoanDetails } from '../../../utils/mathUtils'; // Assuming calculateLoanDetails is imported here
import { 
  updateSelectedClient, 
  updateFilingStatus, 
  updateIncome 
} from '../../../state/client/clientSlice'; // Import Redux actions from clientSlice
import { updateSelectedHome, updatePaymentList } from '../../../state/home/homeSlice'; // Import Redux actions from homeSlice
import './Simple.css';

function Simple() {
  const dispatch = useDispatch();

  // Access Redux state
  const selectedClient = useSelector(state => state.client.selected_client_id);  // Selected client ID
  const selectedHomeDetails = useSelector(state => state.home.selected_home_details);  // Selected home details
  const filingStatus = useSelector(state => state.client.selected_client_details.filing_status);  // Filing status from client
  const income = useSelector(state => state.client.selected_client_details.income);  // Income from client
  const loanDetails = useSelector(state => state.home.loan_details); // Assuming loan details are in Redux

  // Local state
  const [comparisonResults, setComparisonResults] = useState([]);
  const [expandedYear, setExpandedYear] = useState(null);
  const [loanData, setLoanData] = useState({});
  const [paymentList, setPaymentList] = useState([]);
  const [selectedPaymentRange, setSelectedPaymentRange] = useState(0);
  const [years, setYears] = useState(30);
  const [propertyTaxes, setPropertyTaxes] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [taxRate, setTaxRate] = useState(null);
  const [rentAmount, setRentAmount] = useState(0);
  const [rentIncreases, setRentIncreases] = useState(0.03);


  useEffect(() => {
    if (selectedHomeDetails) {
      const results = calculateComparisonForChart(selectedHomeDetails, years, rentAmount);
      setComparisonResults(results);
    }
  }, [selectedHomeDetails, years, rentAmount, loanData]);

  const handleGeneratePDF = () => {
    generatePDF(
      selectedClient,
      loanData,
      selectedHomeDetails,
      paymentList,
      selectedPaymentRange,
      comparisonResults,
      filingStatus,
      income,
      years,
      rentAmount,
      propertyTaxes,
      totalInterest
    );
  };

  return (
    <div className="simple-container">
      <MortgageCalculator
        setLoanData={setLoanData}
        setLoanDetails={(details) => dispatch(updateSelectedHome(details))}  // Dispatch home details update action
        paymentList={paymentList}  // Pass payment list as prop to MortgageCalculator
        setPaymentList={setPaymentList}
        setSelectedClient={(clientId) => dispatch(updateSelectedClient(clientId))}  // Dispatch client update action
        setSelectedPaymentRange={setSelectedPaymentRange}
        setFilingStatus={(status) => dispatch(updateFilingStatus(status))}  // Dispatch filing status update action
        setIncome={(income) => dispatch(updateIncome(income))}  // Dispatch income update action
        setYears={setYears}
        rentAmount={rentAmount}
        setRentAmount={setRentAmount}
        setPropertyTaxes={setPropertyTaxes}
        setTotalInterest={setTotalInterest}
        setTaxRate={setTaxRate}
      />
      {/*<button className="generate-pdf-button" onClick={handleGeneratePDF}>Generate PDF</button>*/}
    </div>
  );
}

export default Simple;
