// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ForgotPassword from './modules/authentication/forgotPassword';
import ResetPassword from './modules/authentication/resetPassword';
import RegisterForm from './modules/authentication/register';
import ConfirmEmail from './modules/confirmEmail';
import HeaderLayout from './modules/HeaderLayout';
import ClientLayout from './modules/ClientLayout';
import Detailed from './modules/pages/detailed/Detailed';
import Simple from './modules/pages/simple/Simple';
import Clients from './modules/pages/clients/Clients';
import Inputs from './modules/pages/inputs/inputs';
import Settings from './modules/pages/Settings';
import { DataProvider } from './modules/dataContext';
import { ClientProvider } from './modules/ClientContext';
import WelcomePage from './modules/pages/clientPresentation/WelcomePage';
import Goals from './modules/pages/clientPresentation/Goals';
import HomeDetails from './modules/pages/clientPresentation/HomeClients/HomeDetails';
import ClientContact from './modules/pages/clientPresentation/ClientContact';
import Assumptions from './modules/pages/clientPresentation/Assumptions';
import AffordabilityExpenditure from './modules/pages/clientPresentation/AffordabilityExpenditure';
import Analysis from './modules/pages/clientPresentation/Analysis';
import Presentation from './modules/pages/clientPresentation/Presentation';
import LandingPage from './modules/authentication/LandingPage';
import MainComponent from './modules/authentication/MainComponent';
import PresentationPreview from './modules/pages/clientPresentation/PresentationPreview';
import Preview from './modules/pages/clientPresentation/Preview'; // Import the Preview component
import store from './state/store'
import { Provider } from 'react-redux'

import 'primereact/resources/themes/saga-blue/theme.css'; // You can choose any theme you prefer
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './theme-overrides.css';
import './App.css';

function App() {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignupOpen, setSignupOpen] = useState(false);

  const openLogin = () => {
    setSignupOpen(false);
    setLoginOpen(true);
  };

  const closeLogin = () => setLoginOpen(false);

  const openSignup = () => {
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const closeSignup = () => setSignupOpen(false);

  return (
    <div className="App">
      <Provider store={store}>
      <DataProvider>
      <ClientProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage openLogin={openLogin} openSignup={openSignup} />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
            <Route path="/confirmEmail/:token" element={<ConfirmEmail />} />
            <Route path="/HeaderLayout" element={<HeaderLayout />}>
              <Route index element={<Detailed />} />
              <Route path="Inputs" element={<Inputs />} />
              <Route path="Detailed" element={<Detailed />} />
              <Route path="Simple" element={<Simple />} />
              <Route path="Clients" element={<Clients />} />
              <Route path="Settings" element={<Settings />} />
            </Route>
            <Route path="/ClientLayout" element={<ClientLayout />}>
              <Route path="WelcomePage" element={<WelcomePage />} />
              <Route path="Client" element={<ClientContact />} />
              <Route path="Goals" element={<Goals />} />
              <Route path="Assumptions" element={<Assumptions />} />
              <Route path="Affordability" element={<AffordabilityExpenditure />} />
              <Route path="Analysis" element={<Analysis />} />
              <Route path="Presentation" element={<Presentation />} />
              <Route path="PresentationPreview" element={<PresentationPreview />} />
              <Route path="Client/:homeId" element={<HomeDetails />} />
            </Route>
            {/* Define Preview as a top-level route */}
            <Route path="/preview/:presentationId" element={<Preview />} /> 
          </Routes>
          <MainComponent
            openLogin={openLogin}
            openSignup={openSignup}
            closeLogin={closeLogin}
            closeSignup={closeSignup}
            isLoginOpen={isLoginOpen}
            isSignupOpen={isSignupOpen}
          />
        </Router>
        </ClientProvider>
        </DataProvider>
      </Provider>
    </div>
  );
}

export default App;
