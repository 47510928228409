import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { calculateLoanDetails, formatNumber } from '../../utils/mathUtils';
import { Tooltip } from 'primereact/tooltip'; // PrimeReact Tooltip
import { DataTable } from 'primereact/datatable'; // PrimeReact DataTable
import { Column } from 'primereact/column'; // PrimeReact Column
import { InputNumber } from 'primereact/inputnumber'; // PrimeReact InputNumber
import './LoanInfoTable.css'; // Custom styles
import { updateTermMonths } from '../../state/home/homeSlice'; // Assuming you have this action

const LoanInfoTable = () => {
  const dispatch = useDispatch();
  // Access Redux state
  const loanData = useSelector(state => state.home.selected_home_details);
  const termMonths = loanData.term_months;
  const years = termMonths / 12;

  const [editableYears, setEditableYears] = useState(years);

  // Update years and dispatch the new term months
  const handleYearsChange = (e) => {
    const newYears = e.value;
    setEditableYears(newYears);
    const updatedTermMonths = newYears * 12;
    dispatch(updateTermMonths(updatedTermMonths)); // Update the Redux state
  };

  // Calculate loan details using the loanData and years from Redux
  const loanDetails = calculateLoanDetails(loanData, editableYears);

  const tooltipContent = {
    loanAmount: `Loan Amount = Home Price - Down Payment<br/>${formatNumber(loanData.home_price)} - ${formatNumber(loanData.down_payment)}<br/>= ${formatNumber(loanDetails.loanAmount)}`,
    interestRate: `Interest Rate = Provided by user<br/>${loanData.interest_rate}%`,
    years: `Years = Selected number of years for the loan<br/>${editableYears} years`,
    paymentsPerYear: `Payments per Year = 12`,
    totalNumPayments: `Total Payments = ${editableYears} years * 12 months = ${(editableYears ?? 0) * 12}`,
    paymentPerPeriod: `Monthly Payment = ${formatNumber(loanDetails.paymentPerPeriod)}`,
    propertyTaxes: `Property Taxes = ${formatNumber(loanDetails.propertyTaxes)}`,
    homeInsuranceFee: `Home Insurance = ${formatNumber(loanData.home_insurance_fee)}`,
    PMICost: `PMI = ${formatNumber(loanDetails.PMICost)}`,
    hoa: `HOA Fees = ${formatNumber(loanData.hoa)}`,
    totalPITI: `Total PITI = ${formatNumber(loanDetails.totalPITI)}`
  };

  const getSymbol = (label) => {
    // Return appropriate symbols based on label
    if (["Interest Rate", "PMI Cost"].includes(label)) {
      return "%";
    }
    if (["Years", "Payments per Year", "Total Number of Payments"].includes(label)) {
      return ""; // No symbol for these fields
    }
    return "$"; // Default to currency symbol
  };

  // Data to display in the table
  const data = [
    { label: "Loan Amount", value: loanDetails.loanAmount, tooltip: tooltipContent.loanAmount },
    { label: "Interest Rate", value: loanData.interest_rate, tooltip: tooltipContent.interestRate },
    { label: "Payments per Year", value: 12, tooltip: tooltipContent.paymentsPerYear },
    { label: "Total Number of Payments", value: (editableYears ?? 0) * 12, tooltip: tooltipContent.totalNumPayments },
    { label: "Payment per Period", value: loanDetails.paymentPerPeriod, tooltip: tooltipContent.paymentPerPeriod },
    { label: "Property Taxes", value: loanDetails.propertyTaxes, tooltip: tooltipContent.propertyTaxes },
    { label: "Home Insurance Fee", value: loanData.home_insurance_fee, tooltip: tooltipContent.homeInsuranceFee },
    { label: "PMI Cost", value: loanDetails.PMICost, tooltip: tooltipContent.PMICost },
    { label: "HOA Fees", value: loanData.hoa, tooltip: tooltipContent.hoa },
    { label: "Total PITI", value: loanDetails.totalPITI, tooltip: tooltipContent.totalPITI }
  ];

  // Custom cell templates to handle the tooltip and value formatting
  const valueTemplate = (rowData) => {
    const symbol = getSymbol(rowData.label);
    const formattedValue = formatNumber(parseFloat(rowData.value).toFixed(2));
    return (
      <span
        data-pr-tooltip={rowData.tooltip}
        data-pr-position="top"
        className="loan-info-value"
      >
        {symbol ? `${formattedValue}${symbol}` : formattedValue}
      </span>
    );
  };

  return (
    <div className="loan-info-table-container">
      <div className="editable-years-container">
        <label htmlFor="editableYears" className="editable-years-label">Years:</label>
        <InputNumber 
          id="editableYears" 
          value={editableYears} 
          onValueChange={handleYearsChange} 
          min={1} 
          max={50} 
          step={1} // Controls the step size
          mode="decimal" // Ensures proper number input
        />
      </div>
      <DataTable value={data} className="loan-info-table">
        <Column field="label" header="Loan Information" />
        <Column field="value" header="Value" body={valueTemplate} />
      </DataTable>
      <Tooltip target=".loan-info-value" />
    </div>
  );
};

export default LoanInfoTable;