import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import './Settings.css';  // Custom CSS for better layout and design

const Settings = () => {
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [feedback, setFeedback] = useState('');
    const navigate = useNavigate();

    const handleChangeEmail = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_email`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ new_email: newEmail, password })
            });

            if (response.ok) {
                alert('Email updated successfully');
            } else {
                const errorData = await response.json();
                alert(`Failed to update email: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error changing email:', error);
        }
    };

    const handleChangePassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_password`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ old_password: oldPassword, new_password: newPassword })
            });

            if (response.ok) {
                alert('Password updated successfully');
            } else {
                const errorData = await response.json();
                alert(`Failed to update password: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    const handleDeleteAccount = async () => {
        const confirmed = window.confirm('Are you sure you want to delete your account? This action is permanent and cannot be undone.');
        if (!confirmed) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_account`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                localStorage.removeItem('token');
                navigate('/');
                alert('Account deleted successfully');
            } else {
                const errorData = await response.json();
                alert(`Failed to delete account: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    const handleSubmitFeedback = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feedback`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ feedback })
            });

            if (response.ok) {
                alert('Feedback submitted successfully');
                setFeedback('');
            } else {
                const errorData = await response.json();
                alert(`Failed to submit feedback: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    return (
        <div className="settings-container">
            <Card title="Settings" className="p-card-modern">
                <div className="settings-section">
                    <h3>Change Email</h3>
                    <div className="input-group">
                        <span className="p-float-label">
                            <InputText id="newEmail" value={newEmail} onChange={e => setNewEmail(e.target.value)} />
                            <label htmlFor="newEmail">New Email</label>
                        </span>
                        <span className="p-float-label">
                            <Password id="password" value={password} onChange={e => setPassword(e.target.value)} toggleMask feedback={false} />
                            <label htmlFor="password">Password</label>
                        </span>
                    </div>
                    <Button label="Change Email" icon="pi pi-check" className="p-button-success full-width" onClick={handleChangeEmail} />
                </div>

                <Divider />

                <div className="settings-section">
                    <h3>Change Password</h3>
                    <div className="input-group">
                        <span className="p-float-label">
                            <Password id="oldPassword" value={oldPassword} onChange={e => setOldPassword(e.target.value)} toggleMask feedback={false} />
                            <label htmlFor="oldPassword">Old Password</label>
                        </span>
                        <span className="p-float-label">
                            <Password id="newPassword" value={newPassword} onChange={e => setNewPassword(e.target.value)} toggleMask feedback={false} />
                            <label htmlFor="newPassword">New Password</label>
                        </span>
                    </div>
                    <Button label="Change Password" icon="pi pi-lock" className="p-button-warning full-width" onClick={handleChangePassword} />
                </div>

                <Divider />

                <div className="settings-section">
                    <h3>Delete Account</h3>
                    <Button label="Delete Account" icon="pi pi-trash" className="p-button-danger full-width" onClick={handleDeleteAccount} />
                </div>

                <Divider />

                <div className="settings-section">
                    <h3>Feedback</h3>
                    <div className="input-group">
                        <span className="p-float-label">
                            <InputTextarea id="feedback" value={feedback} onChange={e => setFeedback(e.target.value)} rows={5} />
                            <label htmlFor="feedback">Your Feedback</label>
                        </span>
                    </div>
                    <Button label="Submit Feedback" icon="pi pi-send" className="p-button-primary full-width" onClick={handleSubmitFeedback} />
                </div>
            </Card>
        </div>
    );
};

export default Settings;