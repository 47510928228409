import { configureStore } from '@reduxjs/toolkit'
import clientReducer from './client/clientSlice'
import homeReducer from './home/homeSlice'
import userReducer from './user/userSlice'


export const store = configureStore({
  reducer: {
    user: userReducer,
    home: homeReducer,
    client: clientReducer,
  }
});

export default store;
