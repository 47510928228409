import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Redux hooks
import { calculateComparison, formatNumber } from '../../utils/mathUtils'; // Your utility functions
import { Dropdown } from 'primereact/dropdown'; // PrimeReact Dropdown
import { Chart } from 'primereact/chart'; // PrimeReact Chart
import { DataTable } from 'primereact/datatable'; // PrimeReact DataTable
import { Column } from 'primereact/column'; // PrimeReact Column
import './ComparisonSection.css'; // Custom styles for your section

const ComparisonSection = ({
  comparisonResults = [], // Ensure this always defaults to an empty array
  setComparisonResults,
  expandedYear,
  setExpandedYear,
}) => {
  const dispatch = useDispatch();

  // Access loan details and rent amount from Redux
  const loanDetails = useSelector((state) => state.home.selected_home_details);
  const years = useSelector((state) => state.home.selected_home_details?.term_months / 12 || 30); // Default to 30 years
  const rentAmount = loanDetails?.rent_amount_monthly || 0; // Fetch rent amount or use 0 as fallback
  const rentIncreases = loanDetails?.rent_increases || 0.03; // Fetch rent increases or default to 3% annually

  const [selectedYear, setSelectedYear] = useState(null); // PrimeReact dropdown selection state
  const [chartData, setChartData] = useState(null); // Chart data state
  const [expandedRentRow, setExpandedRentRow] = useState(null); // Expanded rent row state
  const [expandedWealthRow, setExpandedWealthRow] = useState(null); // Expanded wealth row state

  // Updated Rent Calculation function
  const calculateFutureRentExpenses = (rentAmount, rentIncreases, i) => {
    return 12 * rentAmount * ((Math.pow(1 + rentIncreases, i) - 1) / rentIncreases);
  }

  // Calculate comparison results when loan details or years change
  useEffect(() => {
    if (loanDetails && years && rentAmount) {
      const results = calculateComparison(loanDetails, years, rentAmount);
      setComparisonResults(Array.isArray(results) ? results : []); // Ensure it's always an array
    }
  }, [loanDetails, years, rentAmount, setComparisonResults]);

  useEffect(() => {
    if (Array.isArray(comparisonResults) && comparisonResults.length > 0) {
      const labels = comparisonResults.map((result) => `${result.years} Years`);
  
      const rentExpenses = comparisonResults.map((result) =>
        -calculateFutureRentExpenses(result.details.rentAmount, result.details.rentIncreases, result.years) // Negative values
      );
  
      const netWealth = comparisonResults.map((result) => result.netWealth);
      const sp500Values = comparisonResults.map((result) => result.sp500Value);
  
      const chart = {
        labels: labels,
        datasets: [
          {
            label: 'Rent Expense (Negative)',
            backgroundColor: '#FF6384',
            data: rentExpenses,
          },
          {
            label: 'Own Net Wealth (Positive)',
            backgroundColor: '#36A2EB',
            data: netWealth,
          },
          {
            label: 'SP500 Value (Neutral)',
            backgroundColor: '#FFCE56',
            data: sp500Values,
          },
        ],
      };
  
      setChartData(chart);
    }
  }, [comparisonResults]);
  
  // Adjust chart options to increase height and emphasize large differences
  const chartOptions = {
    maintainAspectRatio: false,  // Allow the chart to stretch based on container
    scales: {
      y: {
        beginAtZero: true,  // Ensure the chart starts at zero
        ticks: {
          callback: function(value) {
            return value.toLocaleString(); // Format y-axis labels with commas for thousands
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };
  
  

  // Function to render expanded row content for Rent Expense
  const renderRentExpansion = (rowData) => {
    return (
      <div className="comparison-box">
        <p>Years: {rowData.years}</p>
        <p>Rent Amount: ${formatNumber(rowData.details.rentAmount)}</p>
        <p>Rent Increases: {formatNumber((rowData.details.rentIncreases * 100).toFixed(2))}%</p>
        <p>Future Rent Expense: ${formatNumber(calculateFutureRentExpenses(rowData.details.rentAmount, rowData.details.rentIncreases, rowData.years).toFixed(2))}</p>
      </div>
    );
  };

  // Function to render expanded row content for Own Net Wealth
  const renderWealthExpansion = (rowData) => {
    return (
      <div className="comparison-box">
        <p>Years: {rowData.years}</p>
        <p>Monthly Mortgage Payment: ${formatNumber(rowData.details.monthlyMortgagePayment)}</p>
        <p>Home Value Over Time: ${formatNumber(rowData.details.homeValueIncreases)}</p>
        <p>Outstanding Loan Balance: ${formatNumber(rowData.details.outstandingLoanBalance)}</p>
        <p>Net Wealth: ${formatNumber(rowData.netWealth.toFixed(2))}</p>
      </div>
    );
  };

  return (
    <div className="comparison-section">
      <h1>Rent vs Own Comparison</h1>

      {/* PrimeReact Dropdown for selecting a year */}
      {/* <div className="p-field">
        <label htmlFor="yearDropdown">Select Year</label>
        <Dropdown
          value={selectedYear}
          options={comparisonResults.map(result => ({
            label: `${result.years} Years`,
            value: result.years,
          }))}
          onChange={(e) => setSelectedYear(e.value)}
          placeholder="Select a Year"
          className="p-dropdown"
        />
      </div> */}

      {/* PrimeReact Chart for visualizing comparison data */}
      {chartData && (
        <div className="p-chart" style={{ height: '600px', width: '100%' }}>  {/* Increase height of container */}
          <Chart 
            type="bar" 
            data={chartData} 
            options={chartOptions} 
            style={{ height: '100%', width: '100%' }} 
          />
        </div>
      )}

      {/* PrimeReact DataTable for displaying comparison results */}
      <div className="comparison-table">
        <DataTable
          value={Array.isArray(comparisonResults) ? comparisonResults : []}
          responsiveLayout="scroll"
        >
          <Column field="years" header="Years" />

          {/* Rent Expense Column with expansion */}
          <Column
            header="Rent Expense"
            body={(rowData) => (
              <>
                <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setExpandedRentRow(expandedRentRow === rowData.years ? null : rowData.years)}>
                  ${formatNumber(calculateFutureRentExpenses(rowData.details.rentAmount, rowData.details.rentIncreases, rowData.years).toFixed(2))}
                  <i className={`pi pi-chevron-${expandedRentRow === rowData.years ? 'down' : 'right'}`} style={{ marginLeft: '10px' }}></i>
                </span>
                {expandedRentRow === rowData.years && renderRentExpansion(rowData)}
              </>
            )}
          />

          {/* Own Net Wealth Column with expansion */}
          <Column
            header="Own Net Wealth"
            body={(rowData) => (
              <>
                <span style={{ color: 'green', cursor: 'pointer' }} onClick={() => setExpandedWealthRow(expandedWealthRow === rowData.years ? null : rowData.years)}>
                  ${formatNumber(rowData.netWealth.toFixed(2))}
                  <i className={`pi pi-chevron-${expandedWealthRow === rowData.years ? 'down' : 'right'}`} style={{ marginLeft: '10px' }}></i>
                </span>
                {expandedWealthRow === rowData.years && renderWealthExpansion(rowData)}
              </>
            )}
          />

          {/* SP500 Value Column */}
          <Column
            header="SP500 Value"
            body={(rowData) => (
              <span style={{ color: 'black' }}>
                ${formatNumber(rowData.sp500Value.toFixed(2))}
              </span>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default ComparisonSection;