// src/utils/mathUtils.js

// Helper function to calculate the monthly payment using the PMT formula
const calculatePMT = (rate, nper, pv, fv = 0, type = 0) => {
  if (rate === 0) return -(pv + fv) / nper;
  const pvif = Math.pow(1 + rate, nper);
  return -rate * pv * (pvif + fv) / (pvif - 1);
};

export const calculateLoanDetails = (loanData, years) => {
  const loanAmount = loanData.home_price - loanData.down_payment;
  const interestRate = loanData.interest_rate / 100;
  const propertyTaxRate = loanData.property_tax_rate / 100;
  const PMIRate = loanData.pmi_rate / 100;
  const paymentsPerYear = 12;
  const totalNumPayments = years * paymentsPerYear;

  const paymentPerPeriod = -calculatePMT(interestRate / paymentsPerYear, totalNumPayments, loanAmount);

  const propertyTaxes = (loanData.home_price * propertyTaxRate) / paymentsPerYear;
  const PMICost = (loanAmount * PMIRate) / paymentsPerYear / 100;
  const totalPITI = paymentPerPeriod + propertyTaxes + loanData.home_insurance_fee + PMICost + loanData.hoa;

  const paymentList = [];
  let remainingBalance = loanAmount;
  let totalInterest = 0;

  for (let i = 0; i < totalNumPayments; i++) {
      const interestPayment = remainingBalance * interestRate / paymentsPerYear;
      const principalPayment = paymentPerPeriod - interestPayment;
      remainingBalance -= principalPayment;
      totalInterest += interestPayment;

      paymentList.push({
          paymentNumber: i + 1,
          paymentAmount: paymentPerPeriod,
          interestAmount: interestPayment,
          principalAmount: principalPayment,
          remainingBalance: remainingBalance
      });
  }

  return {
      loanAmount,
      paymentPerPeriod,
      propertyTaxes,
      PMICost,
      totalPITI,
      paymentList,
      totalInterest
  };
};

export const calculateComparison = (loanData, years, rentAmount, paymentList, totalInterest, propertyTaxes) => {
    const homeValueIncreases = 0.05;
    const rentIncreases = 0.03;
    const SP500IndexFund = 0.10;

    console.log('Rent amount in calc Comparison:', rentAmount);

    const futureRentArray = [];
    const downPaymentInSP500 = [];
    const equityAndROI = [];

    for (let i = 1; i <= years; i++) {
        const futureRent = rentAmount * Math.pow(1 + rentIncreases, i);
        futureRentArray.push({
            years: i,
            amount: futureRent
        });

        const sp500Value = loanData.down_payment * Math.pow(1 + SP500IndexFund, i);
        const roiSP500 = ((sp500Value - loanData.down_payment) / loanData.down_payment) * 100;
        downPaymentInSP500.push({
            years: i,
            amount: sp500Value,
            returnOnInvestment: roiSP500
        });

        const futureHomeValue = loanData.home_price * Math.pow(1 + homeValueIncreases, i);
        const equityGained = futureHomeValue - loanData.home_price;
        const roiHome = (equityGained / loanData.down_payment) * 100;
        equityAndROI.push({
            years: i,
            futureValue: futureHomeValue,
            equityGained: equityGained,
            returnOnInvestment: roiHome.toFixed(2)
        });
    }
    console.log("future rent ARRAYYUYUYYY", futureRentArray);
    return {
        futureRentArray,
        downPaymentInSP500,
        equityAndROI
    };
};

const calculateMonthlyMortgagePayment = (L, r, T) => {
  const monthlyRate = r / 12 / 100; // Convert annual interest rate percentage to monthly decimal
  const n = 12 * T;
  const M = (L * monthlyRate) / (1 - Math.pow((1 + monthlyRate), -n));
  return M;
};

const calculateFutureHomeValue = (P, a, n) => {
  return P * Math.pow(1 + a, n);
};

const calculateOutstandingLoanBalance = (L, r, M, n) => {
  const monthlyRate = r / 12 / 100; // Convert annual interest rate percentage to monthly decimal
  const totalPayments = 12 * n;

  // Ensure monthlyRate is not zero to avoid division by zero errors
  if (monthlyRate === 0) {
    return 0;
  }

  const outstandingBalance = L * Math.pow(1 + monthlyRate, totalPayments) -
    (M / monthlyRate) * (Math.pow(1 + monthlyRate, totalPayments) - 1);
  return outstandingBalance;
};

const calculateNetWorth = (V_n, B_n) => {
  return V_n - B_n;
};

const calculateFutureRentExpenses = (rentAmount, rentIncreases, i) => {
  return 12 * rentAmount * ((Math.pow(1 + rentIncreases, i) - 1) / rentIncreases);
}

export const calculateComparisonForChart = (loanData, years, rentAmount) => {
  const homeValueIncreases = 0.05;
  const rentIncreases = 0.03; // Annual increase rate for rent
  const SP500IndexFund = 0.10;

  console.log('MATH UTILS: Loan Data in Comparison for chart:', loanData);

  const results = [];

  const targetYears = [5, 10, 20, 30];

  // Calculate initial monthly mortgage payment
  const monthlyMortgagePayment = calculateMonthlyMortgagePayment(
    loanData.home_price - loanData.down_payment, // Loan amount is home price minus down payment
    loanData.interest_rate,
    years
  );
  console.log('monthly mortgage in Comparison for chart:', monthlyMortgagePayment);


  for (let i = 1; i <= years; i++) {
    // Calculate the future rent expense using the provided formula
    const futureRentExpense = calculateFutureRentExpenses(loanData.rent_amount_monthly, rentIncreases, i)

    // Calculate the future home value using the provided formula
    const futureHomeValue = calculateFutureHomeValue(loanData.home_price, homeValueIncreases, i);

    // Calculate outstanding loan balance using the provided formula
    const outstandingLoanBalance = calculateOutstandingLoanBalance(
      loanData.home_price - loanData.down_payment, // Loan amount is home price minus down payment
      loanData.interest_rate,
      monthlyMortgagePayment,
      i
    );
    // Calculate net wealth using the provided formula
    const netWealth = calculateNetWorth(futureHomeValue, outstandingLoanBalance);

    // Calculate the value of SP500 investment
    const sp500Value = loanData.down_payment * Math.pow(1 + SP500IndexFund, i);
    const roiSp500 = ((sp500Value - loanData.down_payment) / loanData.down_payment) * 100;

    if (targetYears.includes(i)) {
      results.push({
        years: i,
        futureRentExpense,
        netWealth,
        sp500Value,
        roiSp500,
        appreciation: futureHomeValue - loanData.home_price,
        details: {
          rentAmount: loanData.rent_amount_monthly,
          rentIncreases,
          homePrice: loanData.home_price,
          homeValueIncreases,
          outstandingLoanBalance,
          monthlyMortgagePayment,
          interestRate: loanData.interest_rate,
          downPayment: loanData.down_payment,
          sp500IndexFund: SP500IndexFund,
        }
      });
    }
  }

  return results;
};



export const formatNumber = (num) => {
    return Number(num).toLocaleString();
};