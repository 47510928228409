import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientContext } from '../../ClientContext';
import AddClientModal from './AddClientModal';
import './Clients.css';

import { useSelector, useDispatch } from 'react-redux';
import { 
  setClients, 
  updateSelectedClient, 
  updateFirstName, 
  updateLastName, 
  updateEmailAddress 
} from '../../../state/client/clientSlice';

const Clients = () => {
  const homes = useSelector(state => state.home.homes); // homes is an object

  // Convert homes object to an array
  const homesArray = Object.values(homes);

  const clients = useSelector(state => state.client.clients);
  const selectedClientDetails = useSelector(state => state.client.selected_client_details);
  const selectedClient = useSelector(state => state.client.selected_client_id);

  // const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState('');
  const [bannerMessage, setBannerMessage] = useState(''); // State for the banner message
  const [showBanner, setShowBanner] = useState(false); // State to show/hide the banner
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [selectedClient, setSelectedClient] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const navigate = useNavigate();
  // const { setSelectedClient: setGlobalSelectedClient } = useContext(ClientContext);

  const dispatch = useDispatch();

  const [sortConfig, setSortConfig] = useState({ key: 'date_updated', direction: 'descending' });

  const sortedClients = Object.values(clients).sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  useEffect(() => {
    const fetchClients = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }
  
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_clients`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
  
        const result = await response.json();
        if (response.ok) {
          dispatch(setClients(result)); // Update Redux state
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };
  
    fetchClients();
  }, [dispatch]);
  

  const handleAddClient = async (client) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/add_clients`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(client),
      });
  
      const result = await response.json();
      if (response.ok) {
        dispatch(setClients([...Object.values(clients), result])); // Update Redux state
        showBannerWithMessage('Client added successfully.');
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };
  

  const handleEditClient = async (client) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_clients/${client.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(client)
      });
  
      if (response.ok) {
        const result = await response.json();
        setClients(clients.map(c => c.id === client.id ? result : c));
        showBannerWithMessage('Client updated successfully.');
      } else {
        const result = await response.json();
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };
   

  const handleDeleteClient = async (clientId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_clients/${clientId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
  
      const result = await response.json();
      if (response.ok) {
        dispatch(setClients(Object.values(clients).filter(client => client.client_id !== clientId))); // Remove client from Redux state
        showBannerWithMessage('Client deleted successfully.');
        setShowDeleteModal(false);
        dispatch(updateSelectedClient(null));
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  const handleClientClick = (client) => {
    dispatch(updateSelectedClient(client.client_id)); // Pass client_id, not the full client object
    navigate('/ClientLayout/WelcomePage', { state: { clientId: client.client_id } }); // Use client_id here
  };

  

  const handleCancelDelete = () => {
    setShowDeleteModal(false); // Close the delete modal
    dispatch(updateSelectedClient(null)); // Reset the selected client in Redux
  };

  const handleSave = (client) => {
    if (client.id) {
      handleEditClient(client);
    } else {
      handleAddClient(client);
    }
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const openAddModal = () => {
    dispatch(updateSelectedClient(null));
    setShowAddModal(true);
  };

  const openEditModal = (client) => {
    dispatch(updateSelectedClient(client.client_id)); // Pass client_id to Redux
    setShowEditModal(true);
  };

  const showBannerWithMessage = (message) => {
    setBannerMessage(message);
    setShowBanner(true);
    setTimeout(() => {
      setShowBanner(false);
    }, 3000); // Hide the banner after 3 seconds
  };

  const filteredClients = Object.values(clients).filter(client =>
    client.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.email_address.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  return (
    <div className="clients">
      <h1>Clients</h1>
      {showBanner && <div className="banner"><p>{bannerMessage}</p></div>} {/* Render the banner */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search clients..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={openAddModal}>+</button>
      </div>
      <div className="client-list">
        <div className="client-item-header">
            <p onClick={() => handleSort('first_name')}>
              First Name {sortConfig.key === 'first_name' 
                ? (sortConfig.direction === 'ascending' ? '↑' : '↓') 
                : '↕'}
            </p>
            <p onClick={() => handleSort('last_name')}>
              Last Name {sortConfig.key === 'last_name' 
                ? (sortConfig.direction === 'ascending' ? '↑' : '↓') 
                : '↕'}
            </p>
            <p>Email</p>
            <p onClick={() => handleSort('date_updated')}>
              Last Updated {sortConfig.key === 'date_updated' 
                ? (sortConfig.direction === 'ascending' ? '↑' : '↓') 
                : '↕'}
            </p>
            <p></p>
          </div>
        {sortedClients
          .filter(client =>
            client.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            client.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            client.email_address.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map(client => (
            <div key={client.client_id} className="client-item">
              <div className="client-info" onClick={() => handleClientClick(client)}>
                <p>{client.first_name}</p>
                <p>{client.last_name}</p>
                <p>{client.email_address}</p>
                <p>{new Date(client.date_updated).toLocaleString()}</p>
              </div>
            <div className="three-dot-menu" onClick={(e) => { e.stopPropagation(); setDropdownOpen(dropdownOpen === client.id ? null : client.id); }}>
              <span>⋮</span>
              {dropdownOpen === client.client_id && (
                <div className="three-dot-dropdown" onMouseLeave={() => setDropdownOpen(null)}>
                  <div
                    className="three-dot-dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      openEditModal(client);
                      setDropdownOpen(null);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className="three-dot-dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(updateSelectedClient(client)); // Update selected client in Redux state
                      setShowDeleteModal(true);
                      setDropdownOpen(null);
                    }}
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <AddClientModal
        show={showAddModal || showEditModal}
        onClose={() => {
          setShowAddModal(false);
          setShowEditModal(false);
        }}
        onSave={handleSave}
        initialData={selectedClient || { first_name: '', last_name: '', email: '' }}
      />
      {showDeleteModal && (
        <div className="modal-overlay" onClick={() => setShowDeleteModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this client?</p>
            <div className="modal-buttons">
              <button onClick={() => handleDeleteClient(selectedClient.id)}>Delete</button>
              <button onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Clients;
