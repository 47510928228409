import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import './styling/Assumptions.css';
import { useSelector, useDispatch } from 'react-redux';

const Assumptions = () => {
  const client = useSelector(state => state.client); // Access client slice
  const selectedClientID = client.selected_client_id;
  const selectedClientDetails = client.selected_client_details;
  const [assumptions, setAssumptions] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchAssumptions = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_assumptions?client_id=${selectedClientID}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setAssumptions(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    if (selectedClientID) {
      fetchAssumptions();
    }
  }, [selectedClientID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssumptions({
      ...assumptions,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_assumptions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...assumptions, client_id: selectedClientID })
      });

      const result = await response.json();
      if (response.ok) {
        setMessage('Assumptions updated successfully.');
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="assumptions-page">
      <h2>Assumptions for {selectedClientDetails.first_name}</h2>
      {message && <p>{message}</p>}
      <form>
        <label>
          Current Rent*
          <input type="text" name="current_rent" value={assumptions.current_rent || ''} onChange={handleChange} />
        </label>
        <label>
          Annual rent increase %*
          <input type="text" name="annual_rent_increase" value={assumptions.annual_rent_increase || ''} onChange={handleChange} />
        </label>
        <label>
          Monthly rent insurance
          <input type="text" name="monthly_rent_insurance" value={assumptions.monthly_rent_insurance || ''} onChange={handleChange} />
        </label>
        <label>
          Other monthly rent expense
          <input type="text" name="other_monthly_rent_expense" value={assumptions.other_monthly_rent_expense || ''} onChange={handleChange} />
        </label>
        <p>
          <a href="#" className="tax-benefit-link">How Is The Tax Benefit Calculated?</a>
        </p>
        <button type="button" onClick={handleSubmit}>Save Assumptions</button>
      </form>
    </div>
  );
};

export default Assumptions;
