import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaEllipsisV, FaChevronLeft, FaUser, FaBullseye, FaLightbulb, FaWallet, FaChartLine, FaFileAlt, FaHome } from 'react-icons/fa';
import './ClientLayout.css'; // Import the new CSS
import { updateSelectedClient } from '../state/client/clientSlice';
import { setHomes } from '../state/home/homeSlice'; // Import setHomes action
import { useSelector, useDispatch } from 'react-redux';
import { fetchHomes } from '../utils/fetchHomes'; // Import the fetchHomes function
import { updateUserId, updateEmailAddress, updateUsername } from '../state/user/userSlice';


export const HomesContext = createContext();

const ClientLayout = () => {
  // Access Redux state for client and homes
  const selectedClient = useSelector(state => state.client.selected_client_id);
  const homesSlice = useSelector(state => state.home.homes); // Array of homes from Redux
  const user = useSelector(state => state.user);

  const homes = Object.values(homesSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  // Local state
  const [selectedNav, setSelectedNav] = useState('Dashboard');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 576);
  const [message, setMessage] = useState(''); // For error messages

  // Fetch current user (this logic can be customized based on how you handle auth)
  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, redirecting to login...');
      navigate('/');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const userData = await response.json();
      if (response.ok) {
        dispatch(updateUserId(userData.id));
        dispatch(updateEmailAddress(userData.email));
        dispatch(updateUsername(userData.username));
      } else {
        console.error('Fetch user failed:', userData.msg || 'No error message provided');
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/');
    }
  };

  // Fetch homes for the selected client
  useEffect(() => {
    fetchCurrentUser(); // Fetch current user data

    if (selectedClient) {
      // Use fetchHomes and dispatch the homes to Redux
      fetchHomes(
        selectedClient,
        homesData => dispatch(setHomes(homesData))  // Dispatch the fetched homes to Redux

      );
    }
  }, [selectedClient, dispatch]);

  // Handle window resizing for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 576);
      if (window.innerWidth > 576) {
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle logout
  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        localStorage.removeItem('token');
        navigate('/');
      } else {
        console.error('Logout failed', await response.json());
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const clientNavItems = [
    {
      icon: <FaChevronLeft className="icon" />,
      text: 'Back',
      to: '/HeaderLayout/Clients',
      onClick: () => dispatch(updateSelectedClient(null))
    },
    {
      icon: <FaUser className="icon" />,
      text: 'Client',
      to: '/ClientLayout/Client'
    },
    {
      icon: <FaBullseye className="icon" />,
      text: 'Goals',
      to: '/ClientLayout/Goals'
    },
    {
      icon: <FaLightbulb className="icon" />,
      text: 'Assumptions',
      to: '/ClientLayout/Assumptions'
    },
    {
      icon: <FaWallet className="icon" />,
      text: 'Affordability',
      to: '/ClientLayout/Affordability'
    },
    ...homes.map(home => ({
      icon: <FaHome className="icon" />,
      text: home.address,
      to: `/ClientLayout/Client/${home.home_id}`
    })),
    {
      icon: <FaChartLine className="icon" />,
      text: 'Analysis',
      to: '/ClientLayout/Analysis'
    },
    {
      icon: <FaFileAlt className="icon" />,
      text: 'Presentation',
      to: '/ClientLayout/Presentation'
    }
  ];

  return (
    <HomesContext.Provider value={homes}>
      <div className="layout-container">
        <header className="header d-flex justify-content-between align-items-center">
          <div className="logo">
            <img src={process.env.PUBLIC_URL + "/assets/pf.png"} alt="logo text" className="logo-text" />
          </div>
          <div className="user-info d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle as="button" className="custom-btn" id="dropdown-basic">
                {user.email_address ? user.email_address : 'Account'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item onClick={() => navigate('/HeaderLayout/Settings')}>Settings</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {isMobileView && (
              <button className="mobile-menu-toggle" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <FaEllipsisV />
              </button>
            )}
          </div>
          {isMobileMenuOpen && (
            <div className="mobile-menu">
              {clientNavItems.map((item, index) => (
                <div key={index} className="mobile-menu-item">
                  <NavLink
                    className={`nav-link ${item.text === selectedNav ? 'active' : ''}`}
                    to={item.to}
                    onClick={() => {
                      setSelectedNav(item.text);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    {item.icon}
                    <span>{item.text}</span>
                  </NavLink>
                </div>
              ))}
            </div>
          )}
        </header>
        <div className="sidebar d-flex flex-column justify-content-between">
          <ul className="navbar-nav flex-column">
            <li className="nav-item" style={{ marginTop: '1rem' }}></li>
            {clientNavItems.map((item, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  className={`nav-link ${item.text === selectedNav ? 'active' : ''}`}
                  to={item.to}
                  onClick={() => setSelectedNav(item.text)}
                >
                  {item.icon}
                  <span>{item.text}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <main className="main-content">
          {/* Removed DataContext and simplified layout */}
          <Outlet />
        </main>
      </div>
    </HomesContext.Provider>
  );
};

export default ClientLayout;
