import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTermMonths } from '../../state/home/homeSlice';
import LoanInfoTable from '../components/LoanInfoTable';

const LoanInformation = ({ loanDetails, yearOptions }) => {
  const dispatch = useDispatch();

  // Use the term months from Redux and convert it to years
  const termMonths = useSelector(state => state.home.selected_home_details.term_months);
  const [years, setYears] = useState(termMonths / 12);

  // Update local years state when the termMonths changes
  useEffect(() => {
    setYears(termMonths / 12);
  }, [termMonths]);

  // Function to handle year selection and update term months in Redux
  const handleYearsChange = (e) => {
    const selectedYears = parseInt(e.target.value, 10);
    const termMonths = selectedYears * 12; // Convert years to months
    setYears(selectedYears); // Update local state for years
    dispatch(updateTermMonths(termMonths)); // Dispatch action to update term months in Redux
  };

  if (!loanDetails) {
    // Prevent rendering if loanDetails is not available
    return <div>Loading Loan Information...</div>;
  }

  return (
    <div className="loan-information">
      <label>
      </label>
      <LoanInfoTable loanDetails={loanDetails} years={years} />
    </div>
  );
};

export default LoanInformation;