import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateHome as apiUpdateHome } from '../../utils/updateHome'; // Adjust path as needed

export const updateDatabaseThunk = createAsyncThunk(
  'home/updateDatabase',
  async ({ selected_home_id, selectedHomeDetails }, { dispatch }) => {
    try {
      // Call the API to update the home
      const status = await apiUpdateHome(selected_home_id, selectedHomeDetails);
      console.log('RESPONSE:', status);

      if (status === 'success') {
        console.log('Home successfully updated!');
      } else {
        console.error('Failed to update home');
      }

    } catch (error) {
      console.error('Error updating database:', error);
      throw error;
    }
  }
);



export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    homes: {},  // Object to store homes with home_id as the key
    selected_client_id: -1,  // Store the ID of the selected client
    selected_home_id: -1,  // Store the ID of the selected home
    selected_home_details: {  // Store details of the selected home
      user_id: -1,
      client_id: -1,
      home_address: '',
      home_price: 0,
      down_payment: 0,
      interest_rate: 0,
      property_tax_rate: 0,
      home_insurance_fee: 0,
      pmi_rate: 0,
      hoa_fee: 0,
      rent_amount_monthly: 0,
      term_months: 0,
      loan_type: '',
      interest_only_months: 0,
      balloon_payment_months: 0,
      payment_list: []
    },
    status: 'idle', // To track API call status
    error: null // To store errors
  },
  reducers: {
    // Store all homes as a dictionary (object) with home_id as the key
    setHomes: (state, action) => {
      const homesArray = action.payload;
      console.log('Setting HOMES in Redux:', homesArray);  // Log fetched homes to verify structure

      // Store homes using home_id (or id) as the key
      state.homes = homesArray.reduce((acc, home) => {
        const key = home.home_id || home.id;  // Use home.id as the key
        acc[key] = home;
        return acc;
      }, {});
      
      console.log('Homes in Redux State after setHomes:', state.homes);  // Log state.homes to verify structure
    },
    addHome: (state, action) => {
      const newHome = action.payload;
      state.homes[newHome.home_id] = newHome;
    },
    // Update selected client
    updateSelectedClient: (state, action) => {
      state.selected_client_id = action.payload;
      state.homes = {};
      state.selectedHomeDetails = {  // Store details of the selected home
        user_id: -1,
        client_id: -1,
        home_address: '',
        home_price: 0,
        down_payment: 0,
        interest_rate: 0,
        property_tax_rate: 0,
        home_insurance_fee: 0,
        pmi_rate: 0,
        hoa_fee: 0,
        rent_amount_monthly: 0,
        term_months: 0,
        loan_type: '',
        interest_only_months: 0,
        balloon_payment_months: 0,
        payment_list: []
      }
    },
    // Update selected home and set the details of the selected home
    updateSelectedHome: (state, action) => {
      const homeId = action.payload;
      console.log('Trying to update selected home:', homeId);
      console.log('Current Homes in State:', state.homes);  // Log current homes in state

      if (state.homes[homeId]) {
        state.selected_home_id = homeId;
        state.selected_home_details = { ...state.homes[homeId] };
        console.log('Updated Selected Home ID:', state.selected_home_id);
      } else {
        console.log('Home ID not found in state.homes:', homeId);
      }
    },
    updateSelectedHomeDetails: (state, action) => {
      state.selected_home_details = action.payload;
    },
    // Individual update functions for selected home details
    updateHomePrice: (state, action) => {
      state.selected_home_details.home_price = action.payload;
      if (state.selected_home_id !== null) {
        state.homes[state.selected_home_id].home_price = action.payload;
      }
      console.log('UPDATED HOME PRICE:', state.selected_home_details.home_price);
    },
    updateDownPayment: (state, action) => {
      state.selected_home_details.down_payment = action.payload;
      if (state.selected_home_id !== null) {
        state.homes[state.selected_home_id].down_payment = action.payload;
      }
    },
    updateInterestRate: (state, action) => {
      state.selected_home_details.interest_rate = action.payload;
      if (state.selected_home_id !== null) {
        state.homes[state.selected_home_id].interest_rate = action.payload;
      }
    },
    updatePropertyTaxRate: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].property_tax_rate = action.payload;
        state.selected_home_details.property_tax_rate = action.payload;
      }
    },
    updateHomeInsuranceFee: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].home_insurance_fee = action.payload;
        state.selected_home_details.home_insurance_fee = action.payload;
      }
    },
    updatePMIRate: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].pmi_rate = action.payload;
        state.selected_home_details.pmi_rate = action.payload;
      }
    },
    updateHOAFee: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].hoa_fee = action.payload;
        state.selected_home_details.hoa_fee = action.payload;
      }
    },
    updateRentAmountMonthly: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].rent_amount_monthly = action.payload;
        state.selected_home_details.rent_amount_monthly = action.payload;
      }
    },
    updateTermMonths: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].term_months = action.payload;
        state.selected_home_details.term_months = action.payload;
      }
    },
    updateLoanType: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].loan_type = action.payload;
        state.selected_home_details.loan_type = action.payload;
      }
    },
    updateInterestOnlyMonths: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].interest_only_months = action.payload;
        state.selected_home_details.interest_only_months = action.payload;
      }
    },
    updateBalloonPaymentMonths: (state, action) => {
      if (state.selected_home_id !== -1) {
        state.homes[state.selected_home_id].balloon_payment_months = action.payload;
        state.selected_home_details.balloon_payment_months = action.payload;
      }
    },
    updateSelectedClientForHome: (state, action) => {
        state.selected_client_id = action.payload;
      
        // Reset selected home details when a new client is selected
        state.selected_home_id = null;
        state.selected_home_details = {
          user_id: null,
          client_id: null,
          home_address: '',
          home_price: 0,
          down_payment: 0,
          interest_rate: 0,
          property_tax_rate: 0,
          home_insurance_fee: 0,
          pmi_rate: 0,
          hoa_fee: 0,
          rent_amount_monthly: 0,
          term_months: 0,
          loan_type: '',
          interest_only_months: 0,
          balloon_payment_months: 0,
        };
    },
    updatePaymentList: (state, action) => {
        state.selected_home_details.payment_list = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDatabaseThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDatabaseThunk.fulfilled, (state) => {
        state.status = 'succeeded'; // Mark as succeeded
      })
      .addCase(updateDatabaseThunk.rejected, (state, action) => {
        state.status = 'failed'; // Mark as failed
        state.error = action.error.message; // Store the error for debugging
      });
  }
});

// Action creators are generated for each case reducer function
export const { 
  setHomes, 
  addHome,
  updateSelectedClient,
  updateSelectedHome, 
  updateUserId, 
  updateClientId, 
  updateHomeAddress, 
  updateHomePrice, 
  updateDownPayment, 
  updateInterestRate, 
  updatePropertyTaxRate, 
  updateHomeInsuranceFee, 
  updatePMIRate, 
  updateHOAFee, 
  updateRentAmountMonthly, 
  updateTermMonths, 
  updateLoanType, 
  updateInterestOnlyMonths, 
  updateBalloonPaymentMonths,
  updateSelectedClientForHome,
  updatePaymentList,
  updateSelectedHomeDetails
} = homeSlice.actions;

export default homeSlice.reducer;
