import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';  // Import TieredMenu
import { Ripple } from 'primereact/ripple';
import { useSelector, useDispatch } from 'react-redux';
import DataContext from './dataContext';
import './HeaderLayout.css';

const HeaderLayout = () => {
  const selectedClient = useSelector(state => state.client.selectedClient);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null); // Ref for TieredMenu

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const userData = await response.json();
      if (response.ok) {
        setCurrentUser(userData.email);
      } else {
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        localStorage.removeItem('token');
        navigate('/');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const navItems = [
    { text: 'Clients', to: '/HeaderLayout/Clients', icon: 'pi pi-users' },
    { text: 'Inputs', to: '/HeaderLayout/Inputs', icon: 'pi pi-plus' },
    { text: 'Simple', to: '/HeaderLayout/Simple', icon: 'pi pi-chart-line' },
  ];

  // Account dropdown options using TieredMenu
  const dropdownItems = [
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      command: () => navigate('/HeaderLayout/Settings')
    },
    {
      label: 'Sign Out',
      icon: 'pi pi-sign-out',
      command: handleLogout
    }
  ];

  return (
    <div className="layout-container">
      <header className="header d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/assets/pf.png"} alt="logo" className="logo-text" />
        </div>
        <div className="user-info d-flex align-items-center">
          <span className="user-email mr-2">{currentUser}</span>
          <Button label="Account" icon="pi pi-user" onClick={(event) => menuRef.current.toggle(event)} className="p-button-rounded p-button-text" />
          <TieredMenu model={dropdownItems} popup ref={menuRef} />
        </div>
      </header>

      <div className="sidebar">
        <ul className="navbar-nav flex-column">
          {navItems.map((item, index) => (
            <li className="nav-item" key={index}>
              <NavLink
                to={item.to}
                className="nav-link"
              >
                <i className={item.icon + " mr-2"}></i>
                <span>{item.text}</span>
                <Ripple />
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <main className="main-content">
        <DataContext.Provider value={{ currentUser }}>
          <Outlet />
        </DataContext.Provider>
      </main>
    </div>
  );
};

export default HeaderLayout;