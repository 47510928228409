import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatNumber } from '../../utils/mathUtils';
import { InputText } from 'primereact/inputtext'; // PrimeReact Input
import { Dropdown } from 'primereact/dropdown';  // PrimeReact Dropdown
import { Tooltip } from 'primereact/tooltip'; // PrimeReact Tooltip
import './LoanDetails.css'; // Custom CSS

import { 
  updateHomePrice, 
  updateDownPayment, 
  updateInterestRate, 
  updatePropertyTaxRate, 
  updateHomeInsuranceFee, 
  updatePMIRate, 
  updateHOAFee, 
  updateRentAmountMonthly,
} from '../../state/home/homeSlice';

import {
  updateFilingStatus,
  updateIncome 
} from '../../state/client/clientSlice';

const calculateTaxRate = (filingStatus, income) => {
  const taxBrackets = {
    Single: [
      { min: 0, max: 9875, rate: 10 },
      { min: 9876, max: 40125, rate: 12 },
      { min: 40126, max: 85525, rate: 22 },
      { min: 85526, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 518400, rate: 35 },
      { min: 518401, max: Infinity, rate: 37 }
    ],
    Jointly: [
      { min: 0, max: 19750, rate: 10 },
      { min: 19751, max: 80250, rate: 12 },
      { min: 80251, max: 171050, rate: 22 },
      { min: 171051, max: 326600, rate: 24 },
      { min: 326601, max: 414700, rate: 32 },
      { min: 414701, max: 622050, rate: 35 },
      { min: 622051, max: Infinity, rate: 37 }
    ],
    HeadOfHousehold: [
      { min: 0, max: 14100, rate: 10 },
      { min: 14101, max: 53700, rate: 12 },
      { min: 53701, max: 85500, rate: 22 },
      { min: 85501, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 518400, rate: 35 },
      { min: 518401, max: Infinity, rate: 37 }
    ]
  };

  const brackets = taxBrackets[filingStatus];
  
  // Ensure that income is a number. If it's not, return null or handle it appropriately.
  if (typeof income !== 'number' || isNaN(income)) {
    return null; // You can add a default behavior here if necessary
  }

  for (let i = brackets.length - 1; i >= 0; i--) {
    if (income >= brackets[i].min) {
      return brackets[i].rate;
    }
  }
  
  return null;
};

const LoanDetails = () => {
  const dispatch = useDispatch();

  const loanData = useSelector(state => state.home.selected_home_details);
  const clientDetails = useSelector(state => state.client.selected_client_details);

  const [editingField, setEditingField] = useState(null);
  const [editedValue, setEditedValue] = useState('');
  const [taxRate, setTaxRate] = useState(null);

  useEffect(() => {
    const rate = calculateTaxRate(clientDetails.filing_status, clientDetails.income);
    setTaxRate(rate);
  }, [clientDetails.filing_status, clientDetails.income]);

  const filingStatusOptions = [
    { label: 'Single', value: 'Single' },
    { label: 'Jointly', value: 'Jointly' },
    { label: 'Head of Household', value: 'HeadOfHousehold' }
  ];

  const handleBlur = () => {
    let validValue = editedValue;
  
    // Check if the field being edited is numeric and parse it as a float
    const numericFields = ['home_price', 'down_payment', 'interest_rate', 'property_tax_rate', 'home_insurance_fee', 'pmi_rate', 'hoa_fee', 'rent_amount_monthly', 'income'];
  
    if (numericFields.includes(editingField)) {
      // Remove commas for numeric values, especially for income
      if (editingField === 'income') {
        validValue = validValue.replace(/,/g, ''); // Remove commas
      }
  
      validValue = parseFloat(validValue); // Convert to number
  
      if (isNaN(validValue)) {
        console.error(`Invalid value for ${editingField}:`, editedValue);
        return; // Exit if the value is not a valid number
      }
    }
  
    // Dispatch the appropriate action based on the field being edited
    if (validValue !== loanData[editingField] || validValue !== clientDetails[editingField]) {
      switch (editingField) {
        case 'home_price':
          dispatch(updateHomePrice(validValue)); // Dispatch numeric value
          break;
        case 'down_payment':
          dispatch(updateDownPayment(validValue));
          break;
        case 'interest_rate':
          dispatch(updateInterestRate(validValue));
          break;
        case 'property_tax_rate':
          dispatch(updatePropertyTaxRate(validValue));
          break;
        case 'home_insurance_fee':
          dispatch(updateHomeInsuranceFee(validValue));
          break;
        case 'pmi_rate':
          dispatch(updatePMIRate(validValue));
          break;
        case 'hoa_fee':
          dispatch(updateHOAFee(validValue));
          break;
        case 'rent_amount_monthly':
          dispatch(updateRentAmountMonthly(validValue));
          break;
        case 'filing_status': // No need to parse, it's a string
          dispatch(updateFilingStatus(validValue));
          break;
        case 'income':
          dispatch(updateIncome(validValue)); // Dispatch numeric value for income
          break;
        default:
          break;
      }
    }
  
    // Reset the editing state
    setEditingField(null);
    setEditedValue('');
  };
  
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    } else if (e.key === 'Escape') {
      setEditingField(null);
      setEditedValue('');
    }
  };

  const formatValue = (value, isCurrency = true) => {
    if (value === null || value === undefined || value === '') return 'N/A';
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? value : isCurrency ? `$${formatNumber(parsedValue.toFixed(2))}` : `${parsedValue.toFixed(2)}%`;
  };

  const renderField = (key, label, isCurrency = true) => (
    <div key={key} className="loan-field">
      <span className="field-label">{label}:</span>
      {editingField === key ? (
        <InputText
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="editable-input"
          autoFocus
        />
      ) : (
        <span
          data-pr-tooltip={label}
          data-pr-position="top"
          onDoubleClick={() => setEditingField(key)}
          className="editable-field"
        >
          {formatValue(key.includes('rate') ? loanData[key] : clientDetails[key] || loanData[key], isCurrency)}
        </span>
      )}
      <Tooltip target=".editable-field" />
    </div>
  );

  return (
    <div className="loan-details">
      {renderField('home_price', 'Home Price')}
      {renderField('down_payment', 'Down Payment')}
      {renderField('interest_rate', 'Interest Rate', false)}
      {renderField('property_tax_rate', 'Property Tax Rate', false)}
      {renderField('home_insurance_fee', 'Home Insurance Fee')}
      {renderField('pmi_rate', 'PMI Rate', false)}
      {renderField('hoa_fee', 'HOA Fees')}
      {renderField('rent_amount_monthly', 'Rent Amount')}
      <div className="loan-field">
        <span className="field-label">Filing Status:</span>
        <Dropdown
          value={clientDetails.filing_status}
          options={filingStatusOptions}
          onChange={(e) => dispatch(updateFilingStatus(e.value))}
          placeholder="Select Filing Status"
          className="editable-dropdown"
        />
      </div>
      <div className="loan-field">
        <span className="field-label">Income:</span>
        {editingField === 'income' ? (
          <InputText
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            className="editable-input"
            autoFocus
          />
        ) : (
          <span
            data-pr-tooltip="Income"
            data-pr-position="top"
            onDoubleClick={() => setEditingField('income')}
            className="editable-field"
          >
            {formatValue(clientDetails.income, true)}
          </span>
        )}
        <Tooltip target=".editable-field" />
      </div>
      {taxRate !== null && (
        <div className="loan-field">
          <span className="field-label">Tax Rate:</span>
          <span className="non-editable-field">{taxRate}%</span>
        </div>
      )}
    </div>
  );
};

export default LoanDetails;
